import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import axios from 'axios';

function Header() {
  const { t, i18n } = useTranslation();
  const { user, setUser, initData, initDataUnsafe } = useContext(UserContext);
  const [language, setLanguage] = useState(user.language || 'en');

  const handleLanguageChange = async (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);

    try {
      const response = await axios.post('/updateLanguage', {
        initData,
        initDataUnsafe,
        language: newLanguage
      });
      setUser(response.data.userUpdated);
      i18n.changeLanguage(newLanguage);
    } catch (error) {
      alert(error.response?.data?.error || t('errorUpdatingSettings'));
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t('welcome')}
        </Typography>
        <Select
          value={language}
          onChange={handleLanguageChange}
          sx={{ color: 'white', borderColor: 'white' }}
          variant="outlined"
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="ro">Română</MenuItem>
          <MenuItem value="ru">Русский</MenuItem>
          <MenuItem value="tr">Türkçe</MenuItem>
          <MenuItem value="uk">Українська</MenuItem>
          <MenuItem value="fr">Français</MenuItem>
          <MenuItem value="it">Italiano</MenuItem>
          <MenuItem value="de">Deutsch</MenuItem>
          <MenuItem value="ar">العربية</MenuItem>
          <MenuItem value="hi">हिन्दी</MenuItem>
          <MenuItem value="zh">中文</MenuItem>
        </Select>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
